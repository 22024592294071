<script lang="ts" setup>
const { open } = useNlcModals();

const handleClick = () => {
	open("LazyOModalWheelOfWinsPlayWheel");
};
</script>

<template>
	<div class="wheel-mini" @click="handleClick()">
		<MWheelOfWinsFakeWheel clearSectors />
	</div>
</template>

<style lang="scss" scoped>
.wheel-mini {
	background: var(--custom-rgb-16);
	padding: 10px;
	border-radius: 8px 0 0 8px;
	box-shadow: 0 0 12px 0 var(--custom-4);
	backdrop-filter: blur(5px);
	.fake-wheel {
		width: 30px;
		height: 30px;
	}
}
</style>
